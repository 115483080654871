import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./CuratedDropsPage.module.scss";
import CommonSlider from "../../Common/Ui/CommonSlider/CommonSlider";
import CommonCard from "../../Common/Ui/CommonCard/CommonCard";
import { Link, useNavigate } from "react-router-dom";
import { GridIcon, ListIcon } from "../../../Assets/svgIcons/SVGicon";
import CommonTable from "../../Common/Ui/CommonTable/CommonTable";
import NextRelease from "../Home/NextRelease/NextRelease";
import { useDispatch } from "react-redux";
import { dropsList } from "../../../Features/drop/dropSlice";
import Notfound from "../../Common/NotFound/Notfound";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import { Helmet } from "react-helmet";

const CuratedDropsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [grid, setGrid] = useState(true);
  const [liveDrop, setLiveDrop] = useState([]);
  const [upComingDrop, setUpcomingDrop] = useState([]);
  const [previousDrop, setPreviousDrop] = useState([]);

  useEffect(() => {
    drops();
  }, []);

  const drops = async () => {
    const response = await dispatch(dropsList());
    setUpcomingDrop(response.data.data.upcoming_drops.rows);
    setLiveDrop(response.data.data.live_drops.rows);
    setPreviousDrop(response.data.data.previous_drops.rows);
  };

  const gridHandle = (e) => {
    e.preventDefault();
    setGrid(!grid);
  };

  const rsp = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const Liversp = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
  ];

  const checkNavigation = (e, item) => {
    navigate(`/profile/${item?.artist_name}`);
  };

  return (
    <>
      <section className={styles.CuratedDrops}>
        <Helmet>
          <title>
            Curated Drops | Exclusive Digital Art Collections | NewArtX
          </title>
          <meta
            name="description"
            content="Discover exclusive digital art collections with NewArtX Curated Drops. Stay ahead with unique, handpicked digital fine art by top artists."
          />
          {/* <link rel="canonical" href="https://www.newartx.com/curated-drops" /> */}
        </Helmet>

        <Container>
          <h2 className="big">Live Drops</h2>
        </Container>
        <div className={styles.CuratedDropsBottom}>
          <Container>
            <CommonSlider
              dots={false}
              autoplay={true}
              slidesToShow={4}
              arrows={liveDrop?.length > 4 ? true : false}
              slidesToScroll={1}
              infinite={liveDrop?.length > 4 ? true : false}
              responsive={Liversp}
            >
              {liveDrop.map((item, index) => (
                <>
                  {item.art.length > 0 && (
                    <CommonCard
                      className={`card_withouthover ${styles.cardhover}`}
                      key={index}
                      imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_image}`}
                      onClick={() => navigate(`/drop/${item.id}/live`)}
                      title={item.drop_name}
                      subtitle={`@${item?.artist_name}`}
                      live={item.live}
                      bottomTitle={`Digital Art :${item.art.length}`}
                      // bottomSubTitle={item.art.length}
                      bottomRightTitle={item.bottomRightTitle}
                      bottomRightSubTitle={item.bottomRightSubTitle}
                      item={item}
                    />
                  )}
                </>
              ))}
            </CommonSlider>
            {liveDrop.length === 0 && (
              <Notfound className={styles.notFound} text={"No drop found."} />
            )}
          </Container>
        </div>
      </section>
      <section className={styles.DropCalender}>
        <Container>
          <Row className="align-items-center">
            <Col>
              <h2>Upcoming Drops</h2>
            </Col>
            <Col>
              <div className={styles.DropCalenderAction}>
                <Link to="" className={styles.viewBtn}>
                  View
                </Link>
                <Link
                  to="#"
                  className={`${grid ? "active" : ""} ${
                    styles.DropCalenderActionGrid
                  }`}
                  onClick={gridHandle}
                >
                  <GridIcon />
                </Link>
                <Link
                  to="#"
                  className={`${grid ? "" : "active"} ${
                    styles.DropCalenderActionList
                  }`}
                  onClick={gridHandle}
                >
                  <ListIcon />
                </Link>
              </div>
            </Col>
          </Row>
          {grid ? (
            <Row>
              {upComingDrop.map((item, index) => (
                <>
                  {item.art.length > 0 && (
                    <Col sm={6} lg={4} xl={3} key={index} className="mt-5">
                      <CommonCard
                        className={`card_withouthover ${styles.cardhover}`}
                        month={item.month}
                        date={item.date}
                        time={item.time}
                        imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_image}`}
                        onClick={() => navigate(`/drop/${item.id}/upcoming`)}
                        title={item.drop_name}
                        subtitle={`@${item?.artist_name}`}
                        bottomTitle={`Digital Art :${item.art.length}`}
                        // bottomSubTitle={item.art.length}
                        // bell="true"
                        item={item}
                      />
                    </Col>
                  )}
                </>
              ))}
              {upComingDrop.length === 0 && (
                <Notfound text={"No drop found."} />
              )}
            </Row>
          ) : (
            <CommonTable className={styles.drop_table}>
              <thead>
                <tr>
                  <th>Drop Name</th>
                  <th>Artist</th>
                </tr>
              </thead>
              <tbody>
                {upComingDrop.map((item, index) => (
                  <>
                    {item.art.length > 0 && (
                      <tr
                        key={index}
                        onClick={item.onClick}
                        className="cursor-pointer"
                      >
                        <td
                          onClick={() => navigate(`/drop/${item.id}/upcoming`)}
                        >
                          <div className="d-flex align-items-center thumb-outer">
                            <img
                              className="thumb"
                              src={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_image}`}
                              alt="img"
                            />{" "}
                            <h4>{item.drop_name}</h4>
                          </div>
                        </td>
                        <td onClick={(e) => checkNavigation(e, item)}>
                          <h4>@{item.artist_name}</h4>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
                {upComingDrop.length === 0 && (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No drop found.
                    </td>
                  </tr>
                )}
              </tbody>
            </CommonTable>
          )}
        </Container>
      </section>
      <section className={styles.CuratedDropsNeverMissDrop}>
        <NextRelease />
      </section>
      <section className={`${styles.CuratedDrops} ${styles.previousDrops}`}>
        <Container>
          <div className={styles.headingtext}>
            <h2>Previous Drops</h2>
            {previousDrop?.length !== 0 && (
              <Link
                to={RoutesUrls.Previous_drop}
                className={styles.viewbtnprevious}
                onClick=""
              >
                View All
              </Link>
            )}
          </div>
        </Container>
        <div className={styles.CuratedDropsBottom}>
          <Container>
            <CommonSlider
              className={styles.CuratedDropsBottom_sliderbar}
              dots={false}
              autoplay={true}
              slidesToShow={5}
              arrows={previousDrop?.length > 5 ? true : false}
              slidesToScroll={1}
              infinite={previousDrop?.length > 5 ? true : false}
              responsive={rsp}
            >
              {previousDrop?.map((item, index) => (
                <>
                  {item.art.length > 0 && (
                    <CommonCard
                      className={`${styles.cardhover} card_withouthover`}
                      key={index}
                      imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_image}`}
                      onClick={() => navigate(`/drop/${item.id}/previous`)}
                      title={item.drop_name}
                      subtitle={`@${item?.artist_name}`}
                      bottomTitle={`Digital Art :${item.art.length}`}
                      // bottomSubTitle={item.art.length}
                      item={item}
                    />
                  )}
                </>
              ))}
            </CommonSlider>
            {previousDrop?.length === 0 && (
              <Notfound
                className={styles.notFound_clr}
                text={"No drop found."}
              />
            )}
          </Container>
        </div>
      </section>
    </>
  );
};
export default CuratedDropsPage;
