import React from "react";
import { Container } from "react-bootstrap";
import TopSection from "./Component/TopSection/TopSection";
import NewArtLogo from "../../../Assets/images/NewArtLogo.png";
import AppstoreBtn from "../../../Assets/images/AppstoreBtn.png";
import Androidbtn from "../../../Assets/images/Androidbtn.png";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import MobileApp from "../../../Assets/images/MobileApp.png";
import displayDigital from "../../../Assets/images/displayDigital.png";
import style from "./HowtoflauntDfa.module.scss";
import { Helmet } from "react-helmet";

const HowtoflauntDfa = () => {
  return (
    <>
      <section className={style.howtoflaunt}>
        <Helmet>
          <title>Display Your NFTs in Style with NAXCanvas</title>
          <meta
            name="description"
            content="Introducing NAXCanvas, the art screen specifically designed to showcase your NFTs (DFAs) in all their glory."
          />
          {/* <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Display Your NFTs in Style with NAXCanvas | NewartX"
          />
          <meta
            property="og:url"
            content="https://www.newartx.com/flaunt-nfts"
          />
          <meta
            property="og:image"
            content="https://www.newartx.com/static/media/Nax%20Canvas.1496f78d0023808b36e5.png"
          />
          <meta
            property="og:description"
            content="Introducing NAXCanvas, the art screen specifically designed to showcase your NFTs (DFAs) in all their glory."
          />
          <link rel="canonical" href="https://www.newartx.com/flaunt-nfts" /> */}
        </Helmet>
        <TopSection />
        <Container>
          <div className={style.howtoflaunt_innerPart}>
            <div className={style.getApp}>
              <div className={style.getAppLeftSide}>
                <h2 className="big">
                  Get The <img src={NewArtLogo} alt="NewArt-Logo" /> App
                </h2>
                <h5>
                  “NewArtX, a cutting-edge platform dedicated to Digital Fine
                  Art, offers a seamless and captivating way to flaunt your DFAs
                  on various devices, including Android and iOS devices such as
                  tablets, mobile phones, and even TVs.­­­­{" "}
                </h5>
                <h5>
                  Beginning your journey into the world of Digital Fine Art
                  appreciation is easy, all you have to do is download the
                  NewArtX app, available for both Android and iOS platforms.
                  Once installed, users can login to their NewArtX accounts and
                  gain access to their purchased DFAs. The app provides an
                  intuitive and user-friendly interface, allowing you to
                  effortlessly browse through your digital art collection.”
                </h5>
              </div>
              <div className={style.getAppRightSide}>
                <div className={style.tapImg}>
                  <img src={MobileApp} alt="Mobile-App" />
                </div>
                <div className={style.btnSec}>
                  <CommonBtn
                    className={`bg-border-without ${style.btnApp}`}
                    icon={<img src={AppstoreBtn} alt="app-btn" />}
                    role="btn"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/in/app/newartx-display/id6670194903",
                        "__blank"
                      )
                    }
                  />
                  <CommonBtn
                    className={`bg-border-without ${style.btnApp}`}
                    icon={<img src={Androidbtn} alt="android-btn" />}
                    role="btn"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.newartx.app&pcampaignid=web_share",
                        "__blank"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className={style.digitalDisplay}>
              <div className={style.digitalDisplayLeftSide}>
                <div className={style.tapImg}>
                  <img src={displayDigital} alt="display-Digital" />
                </div>
              </div>
              <div className={style.digitalDisplayRightSide}>
                <h2 className="big">Digital Display Screens</h2>
                <h5>
                  What sets NewArtX apart is its commitment to enhancing the
                  viewing experience of DFAs. For those seeking a premium
                  showcase for their digital masterpieces, NewArtX introduces
                  the NAX Canvas. This specially manufactured Art screen is
                  designed to showcase your DFAs in all their glory, ensuring
                  that each stroke and detail is presented with unparalleled
                  clarity and vibrancy.
                </h5>
                <h5>
                  The NAX Canvas is not just a display; it’s a testament to the
                  fusion of technology and artistry. Crafted to complement the
                  aesthetics of any space, the NAXCanvas adds a touch of
                  sophistication to your surroundings. Whether placed in your
                  living room, office, or personal art studio, the NAX Canvas
                  transforms any space into a dynamic gallery, bringing your
                  DFAs to life in a way they truly deserve.
                </h5>
                <CommonBtn
                  className={`${style.preOrder}`}
                  title="Pre-order"
                  role="btn"
                  onClick={() =>
                    window.open("https://naxcanvas.com/", "__blank")
                  }
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HowtoflauntDfa;
