import React from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import styles from "./ShippingPolicy.module.scss";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  return (
    <>
      <div className={styles.terms_and_use}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
          {/* <link
            rel="canonical"
            href="https://www.newartx.com/shipping-policy"
          /> */}
        </Helmet>
        <Container>
          <div className={styles.terms_and_use_in} id="shipping_policy">
            <h2>Shipping Policy</h2>

            <h4>1. Digital Delivery</h4>
            <p>
              All Digital Fine Art (DFAs) listed on NewArtX are digital assets,
              and delivery is conducted electronically to the buyer's designated
              account within our platform. No physical shipping is involved in
              the purchase process.
            </p>

            <h4>2. Instant Delivery</h4>
            <p>
              AUpon successful completion of the purchase transaction, buyers
              will gain immediate access to the DFA(s) they have acquired. Our
              platform ensures swift and seamless delivery of digital assets for
              a hassle-free experience.
            </p>

            <h4>3. Ownership Transfer</h4>
            <p>
              Ownership of the DFA(s) will be transferred to the buyer's account
              within our centralized database upon confirmation of the purchase.
              Once the transaction is finalized, the buyer assumes full control
              and ownership rights over the acquired DFA(s).
            </p>

            <h4>4. Transaction Confirmation</h4>
            <p>
              Buyers will receive a confirmation notification within their
              account on NewArtX upon successful completion of the purchase.
              This confirmation will include details of the transaction, such as
              the DFA(s) acquired and the transaction ID.
            </p>

            <h4>5. Verification Process</h4>
            <p>
              All transactions and ownership records are securely managed within
              our centralized database. Our platform employs robust security
              measures to ensure the integrity and authenticity of each
              transaction. Buyers can verify their DFA(s) ownership by accessing
              their account dashboard.
            </p>

            <h4>6. Customer Support</h4>
            <p>
              For any assistance or inquiries regarding your DFA purchases, our
              dedicated customer support team is available to assist you. Please
              reach out to us via email at namaste@newartx.com.
            </p>

            <h4>7. Policy Updates</h4>
            <p>
              NewArtX reserves the right to update or modify this Shipping
              Policy as needed without prior notice. Any revisions will be
              effective immediately upon posting on our website. It is the
              buyer's responsibility to review this policy periodically for any
              changes.
            </p>
            <div className={styles.terms_and_sales}>
              <p>
                By proceeding with your purchase of DFA on{" "}
                <Link to="https://newartx.com/">NewArtX.com</Link>, you
                acknowledge that you have read, understood, and agreed to our
                Shipping Policy.
              </p>
              <p>
                If you have any questions or concerns regarding this policy,
                please do not hesitate to contact us for clarification.
              </p>
              <p>
                Thank you for choosing NewArtX for your Digital Fine Art
                acquisitions. Happy browsing!
              </p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ShippingPolicy;
