import React from "react";
import styles from "./RefundandReturnsPolicy.module.scss";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const RefundandReturnsPolicy = () => {
  return (
    <>
      <div className={styles.terms_and_use}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
          {/* <link rel="canonical" href="https://www.newartx.com/refund-returns-policy" /> */}
        </Helmet>

        <Container>
          <div className={styles.terms_and_use_in} id="refund_policy">
            <h2>Refund and Returns Policy</h2>

            <p>
              Given the digital nature of DFAs and our centralized transaction
              management system, all sales are final once the purchase
              transaction is completed. Refunds or cancellations are not
              possible, except in cases of proven fraudulent activity, as
              determined by our platform.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default RefundandReturnsPolicy;
