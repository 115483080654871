import React from "react";
import styles from "./TermsOfUse.module.scss";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const TermsOfUse = () => {
  return (
    <>
      <div className={styles.terms_and_use}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
          {/* <link rel="canonical" href="https://www.newartx.com/terms_of_use" /> */}
        </Helmet>
        <Container>
          <div className={styles.terms_and_use_in} id="terms_use">
            <h1>Terms of Use</h1>
            <p>
              Welcome to NewArtX! We appreciate your presence on our platform.
              Before proceeding, please read and understand the terms and
              conditions outlined below. By accessing and using our website, you
              signify your agreement to comply with and be bound by these terms.
              If any part of these terms is not acceptable to you, kindly
              refrain from using our platform.
            </p>

            <h4>1. Acceptance of Terms</h4>
            <p>
              By utilizing NewArtX, you acknowledge that you have carefully
              read, comprehended, and consent to be bound by these Terms and
              Conditions. These terms are applicable to all users of the site,
              including artists, buyers, and visitors.
            </p>

            <h4>Additional Detailed Sections</h4>
            <ul>
              <li>Terms and Conditions and Their Acceptance </li>
            </ul>
            <h4>Account Creation - Identity Verification</h4>
            <ul>
              <li>
                Users are required to create an account, providing accurate
                information.
              </li>
              <li>
                Identity verification processes may be implemented for security.
              </li>
            </ul>
            <h6>Access</h6>
            <ul>
              <li>
                Users are granted access to certain features upon account
                creation.
              </li>
            </ul>
            <h6>Security</h6>
            <ul>
              <li>
                Users are responsible for maintaining the confidentiality and
                security of their NewArtX accounts.{" "}
              </li>
            </ul>
            <h6>Multiple Accounts</h6>
            <ul>
              <li>
                The creation of multiple accounts without explicit permission is
                strictly prohibited.
              </li>
            </ul>
            <h4>Platform Fees/Trading Fee and Payment Terms</h4>
            <ul>
              <li>
                By buying or selling a Digital Fine Art on NewArtX, you agree to
                pay all applicable fees and you authorize NewArtX to
                automatically deduct fees directly from your payment. You will
                always be provided with a breakdown of fees prior to your
                purchase or sale of a Digital Fine Art.
              </li>
            </ul>
            <h4> Unclaimed Account/Property</h4>
            <ul>
              <li>
                if NewArtX is holding funds and Digital Fine Art in your account
                and has no record of your use of the Service for several years,
                we may be required, upon passage of applicable time periods, to
                report these funds as unclaimed property in accordance with the
                applicable Indian laws.{" "}
              </li>
            </ul>
            <h4>Prohibited Activities and Account Suspension</h4>
            <ul>
              <li>
                {" "}
                Users are expressly prohibited from engaging in activities that
                violate applicable laws, infringe on the rights of others, or
                disrupt the normal functioning of NewArtX. Non-compliance may
                result in account suspension pending investigation.
              </li>
            </ul>

            <h4> Accidental Transfer</h4>
            <ul>
              <li>
                In case of accidental transfers, users should promptly contact
                our support for resolution and guidance.
              </li>
            </ul>
            <h4> Account Closure</h4>
            <ul>
              <li>
                Users have the right to request the closure of their accounts.
                For Closure processes contact our support for guidance.
              </li>
            </ul>
            <h4> Communication</h4>
            <ul>
              <li>
                Users understand and agree to receive important updates,
                announcements, and information from NewArtX through the provided
                contact details.
              </li>
            </ul>
            <h4>
              <a
                className="termsLink"
                href="https://newartx.com/terms_of_use#CancellationRefund"
              >
                {" "}
                Terms of Sale
              </a>
            </h4>
            <div className={styles.terms_and_sales}>
              <p>
                By placing a bid or an order on or through NewArtX, you agree
                that you are submitting a binding offer to purchase Digital Fine
                Art. Your order is accepted and confirmed once purchase is
                complete, and NewArtX displays the Confirmation Page
                (“Confirmation Page”). YOU HEREBY EXPRESSLY AGREE THAT THE
                SUPPLY OF A DIGITAL FINE ART BEGINS IMMEDIATELY AFTER THE
                CONFIRMATION PAGE IS DISPLAYED.
              </p>
              <p>
                {" "}
                No refunds or returns are permitted except with respect to any
                statutory warranties or guaranties that cannot be excluded or
                limited by law.
              </p>
              <p>
                {" "}
                Creators or Publishers may choose to provide certain rights to
                holders of their Digital Fine Art, which may include, but is not
                limited to, physical items, special access, or other exclusive
                content (collectively, “Additional Items”). Any Additional Items
                will be provided directly to you from the Creator or Publisher;
                NewArtX has no involvement in such transactions and is not a
                party to such transactions. Notwithstanding the above, NewArtX
                may allow the Creator or Publisher to publish a description of
                the Additional Items on the Site. If you own a Digital Fine Art
                that entitles you to Additional Items, you hereby consent to
                NewArtX sharing your contact information with the Creator or
                Publisher to facilitate delivery of your Additional Items.
              </p>
              <p>
                {" "}
                Unless otherwise stated, transactions on the Secondary Market
                are only for Digital Fine Art and do not grant the purchaser the
                right to any Additional Items. NewArtX will receive payments on
                behalf of the seller of a Digital Fine Art as the seller’s
                agent. If you are a seller of a Digital Fine Art, you appoint
                NewArtX as your agent for the limited purpose of receiving,
                holding and settling payments by buyers of the Digital Fine Art
                you sell.{" "}
              </p>
              <p>
                A payment received by us from a buyer, on the seller’s behalf,
                satisfies the buyer’s obligation to the seller in the amount of
                the payment received, regardless of whether we actually settle
                such payment to the seller. In the event that we do not make any
                such payment to a seller as described in these Terms of Use, the
                seller will have recourse against us only and not against the
                buyer, as such payment is deemed to be made by the buyer to the
                seller upon receipt by us. To the extent permissible under the
                rules of any third-party or governmental body with jurisdiction
                over us, the seller agrees that we are not liable for seller’s
                acts and omissions and understands that we disclaim any such
                liability.{" "}
              </p>
            </div>
            <h4>Standard Clauses</h4>
            <ul>
              <li>
                Disclaimer: Information on NewArtX is provided “as is” without
                warranties of any kind.{" "}
              </li>
              <li>
                Indemnification: Users agree to indemnify and hold NewArtX
                harmless from any claims or liabilities arising out of their use
                of the platform.
              </li>
              <li>
                Limitation of Liability: NewArtX’s liability is limited to the
                extent permitted by applicable law.
              </li>
              <li>
                General: Miscellaneous provisions govern the overall
                relationship between users and NewArtX.
              </li>
              <li>
                Applicable Law: These terms are governed by the laws of India
                and users submit to the exclusive jurisdiction of its courts.
              </li>
              <li>
                {" "}
                Dispute Resolution: Disputes are resolved through
                [Arbitration/Mediation/Court] in jurisdiction of Mumbai and are
                governed by the laws of India.{" "}
              </li>
              <li>
                Severability and Entire Agreement: If any provision is deemed
                unenforceable, the remaining provisions remain in effect, and
                the terms constitute the entire agreement between users and
                NewArtX.
              </li>
              <li>
                {" "}
                Survival: Certain provisions, including but not limited to those
                related to intellectual property and user conduct, survive
                termination.{" "}
              </li>
              <li>
                Contact Information: Users can contact us at namaste@newartx.com
                or through the Contact Us page on NewArtX for any inquiries or
                concerns.{" "}
              </li>
              <li>Refund not applicable under any circumstances. </li>
            </ul>
            <h4> Modification of Term</h4>
            <p>
              You agree and understand that we may modify part or all of NewArtX
              or the Services without notice.
            </p>
            <h4> Contact information</h4>
            <p>
              If you have any questions, would like to provide feedback, or
              would like more information about NewArtX, please feel free to
              email us at namaste@newartx.com If you would like to lodge a
              complaint, please contact us at namaste@newartx.com or write to us
              at: NewArtX, Neelam Centre, B 401- 404, Hind Cycle Marg, Worli,
              Mumbai, Maharashtra 400030.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TermsOfUse;
