import React from "react";
import style from "./AboutNewArtX.module..scss";
import BenefitsNewart from "./BenefitsNewart/BenefitsNewart";
import NewArtXBanner from "./NewArtXBanner/NewArtXBanner";
import { Helmet } from "react-helmet";

const AboutNewArtX = () => {
  return (
    <>
      <section className={style.aboutNewart}>
        <Helmet>
          <title>About NewartX - a curated Digital Fine Art platform</title>
          <meta
            name="description"
            content="NewartX is a digital fine art platform that connects art enthusiasts with a curated selection of original artwork from talented artists. Discover unique digital fine art to enhance your home or office."
          />
          {/* <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="About NewartX - a curated Digital Fine Art platform"
          />
          <meta
            property="og:url"
            content="https://www.newartx.com/about-newartx"
          />
          <meta
            property="og:image"
            content="https://www.newartx.com/static/media/Nax%20Canvas.1496f78d0023808b36e5.png"
          />
          <meta
            property="og:description"
            content="NewartX is a digital fine art platform that connects art enthusiasts with a curated selection of original artwork from talented artists. Discover unique digital fine art to enhance your home or office."
          />
          <link rel="canonical" href="https://www.newartx.com/about-newartx" /> */}
        </Helmet>
        <NewArtXBanner />
        <BenefitsNewart />
      </section>
    </>
  );
};

export default AboutNewArtX;
